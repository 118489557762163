.container {
  margin: 10px;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
  border: 1px solid lightgray;
  border-radius: 2px;
}

.error {
  color: red;
}